<template>
    <div>
        <div class="mb-4">
            <a-input-search
                placeholder="Поиск"
                v-model="searchText"
                allowClear
                :size="isMobile ? 'large' : 'default'"
                @change="search"/>
        </div>
        <a-table
            :columns="columns"
            :pagination="false"
            class="org_user_table flex flex-col"
            :loading="loading"
            :locale="{
                emptyText: 'Нет данных'
            }"
            :size="tableSize"
            :row-key="record => record.id"
            :data-source="employeeList">
            <template
                slot="full_name"
                slot-scope="text, record">
                <div class="flex items-center">
                    <Profiler
                        :user="record"
                        initStatus
                        hideSupportTag
                        :avatarSize="28" />
                    <template v-if="record.is_org_admin || record.is_support || isAuthor(record.id)">
                        <div class="ml-2">
                            <a-tag 
                                v-if="record.is_org_admin" 
                                color="green" 
                                class="tag tag_custom_margin" 
                                v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }" 
                                content="Администратор">
                                Админ
                            </a-tag>
                            <a-tag 
                                v-if="record.is_support" 
                                color="green" 
                                class="tag tag_custom_margin" 
                                v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }" 
                                content="Техподдержка">
                                <i class="fi fi-rr-headset"></i>
                            </a-tag>
                            <a-tag 
                                v-if="isAuthor(record.id)" 
                                color="green" 
                                class="tag tag_custom_margin" 
                                v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }" 
                                content="Руководитель">
                                <i class="fi fi-rr-crown"></i>
                            </a-tag>
                        </div>
                    </template>
                </div>
            </template>
            <template
                slot="email"
                slot-scope="text, record">
                <i class="fi fi-rr-envelope"></i>
                <a :href="`mailto:${record.email}`">
                    {{ record.email }}
                </a>
            </template>
            <template
                slot="job_title"
                slot-scope="text">
                {{ text }}
            </template>            
            <template
                slot="last_activity"
                slot-scope="text, record">
                <template v-if="record.last_activity">
                    <span></span>
                    {{ $moment(record.last_activity).format('DD.MM.YYYY HH:mm') }}
                </template>
            </template>
            <template
                slot="id"
                slot-scope="text, record">
                <a-button 
                    v-if="showRemoveEmployeeButton(record)" 
                    type="link" 
                    v-tippy="{ touch: false }" 
                    content="Исключить пользователя"
                    class="text_current ant-btn-icon-only" 
                    @click="deleteUser(record)">
                    <i class="fi fi-rr-remove-user"></i>
                </a-button>
                <a-button 
                    v-if="showLeaveButton(record)" 
                    type="danger" 
                    ghost
                    @click="leaveOrg()">
                    Покинуть организацию
                </a-button>
            </template>
        </a-table>

        <div class="flex justify-end pt-1">
            <a-pagination
                :current="page"
                size="small"
                :show-size-changer="pageSizeOptions.length > 1"
                :page-size.sync="pageSize"
                :defaultPageSize="Number(pageSize)"
                :pageSizeOptions="pageSizeOptions"
                :total="employeeCount"
                show-less-items
                @showSizeChange="sizeSwicth"
                @change="changePage">
                <template slot="buildOptionText" slot-scope="props">
                    {{ props.value }}
                </template>
            </a-pagination>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import debounce from '@/utils/lodash/debounce'
import eventBus from '@/utils/eventBus'
export default {
    props: {
        org: {
            type: Object,
            required: true
        },
        minusUserCount: {
            type: Function,
            default: () => {}
        },
        updateTableRowsHeight: {
            type: Function,
            default: () => {}
        },
        actions: {
            type: Object,
            default: () => null
        },
        page_name: {
            type: String,
            default: 'orgInfoDrawer'
        },
        model: {
            type: String,
            default: 'users.ProfileModel'
        },
        isDepartment: {
            type: Boolean,
            default: false
        },
        parentId: {
            type: String,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapState({
            windowHeight: state => state.windowHeight,
            windowWidth: state => state.windowWidth,
            config: state => state.config.config,
            isMobile: state => state.isMobile,
            user: state => state.user.user,
            employees: state => state.organization.employees
        }),
        employeeList() {
            return this.employees?.[this.org.id]?.results
        },
        employeeCount() {
            return this.employees?.[this.org.id]?.count
        },
        tableSize() {
            return this.config?.theme?.tableSize ? this.config.theme.tableSize : 'small'
        }
    },
    created() {
        this.getList()
    },
    data() {
        return {
            page: 1,
            pageSize: 15,
            pageSizeOptions: ['15', '30', '50'],
            sort: '',
            count: 0,
            columns: [
                {
                    dataIndex: 'full_name',
                    title: 'ФИО',
                    key: 'full_name',
                    scopedSlots: { customRender: 'full_name' }
                },
                {
                    dataIndex: 'email',
                    title: 'E-mail',
                    key: 'email',
                    scopedSlots: { customRender: 'email' }
                },
                {
                    dataIndex: 'job_title',
                    title: 'Должность',
                    key: 'job_title',
                    scopedSlots: { customRender: 'job_title' }
                },
                {
                    dataIndex: 'last_activity',
                    title: 'Последняя активность',
                    key: 'last_activity',
                    scopedSlots: { customRender: 'last_activity' }
                },
                {
                    dataIndex: 'id',
                    title: '',
                    key: 'id',
                    scopedSlots: { customRender: 'id' }
                },
            ],
            list: [],
            loading: false,
            searchText: '',
            searchStart: false
        }
    },
    mounted () {
        eventBus.$on(`update_filter_${this.model}_${this.page_name}`, () =>{
            this.$nextTick(() => {
                this.getList()
            })
        })
    },
    beforeDestroy() {
        eventBus.$off(`update_filter_${this.model}_${this.page_name}`)
    },
    methods: {
        ...mapActions({
            getEmployeeList: 'organization/getEmployeeList'
        }),
        showLeaveButton(record) {
            return (this.user?.id === record.id) && !this.actions?.edit
        },
        showRemoveEmployeeButton(record) {
            return !this.isAuthor(record.id) && (this.actions?.edit) && (this.user?.id !== record.id)
        },
        search: debounce(async function() {
            if(this.searchText.length > 1) {
                try {
                    await this.getList()
                } catch(e) {

                } finally {
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                }
                
            } else{ 
                await this.getList()
            }
        },500),
        leaveOrg() {
            this.$confirm({
                title: 'Вы действительно хотите покинуть организацию?',
                okText: 'Покинуть',
                okType: 'danger',
                cancelText: 'Отмена',
                closable: true,
                maskClosable: true,
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post(`/users/my_organizations/${this.org.id}/leave/`)
                            .then(() => {
                                this.$message.info('Вы успешно покинули организацию')
                                eventBus.$emit('orgTableReload')
                                resolve(true)
                            })
                            .catch((error) => { 
                                this.$message.error('Ошибка')
                                reject(error)
                            })
                    })
                }
            })
        },
        deleteUser(record) {
            this.$confirm({
                title: 'Удалить пользователя из организации?',
                okText: 'Удалить',
                okType: 'danger',
                cancelText: 'Отмена',
                closable: true,
                maskClosable: true,
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        const url = this.isDepartment ? `/users/my_organizations/departments/${this.org.id}/users/delete/`
                            : `/users/my_organizations/${this.org.id}/users/delete/`

                        this.$http.post(url, { id: record.id })
                            .then(() => {
                                this.$message.info('Пользователь исключен из организации')

                                // 
                                this.org.id
                                this.$store.commit('organization/DELETE_EMPLOYEE', {
                                    organizationId: this.org.id,
                                    employeeId: record.id,
                                    parentId: this.parentId,
                                    isDepartment: this.isDepartment
                                })
                                // 

                                const index = this.list.findIndex(f => f.id === record.id)
                                if(index !== -1) {
                                    this.list.splice(index, 1)
                                    this.count = this.count - 1
                                    this.minusUserCount(this.org)
                                }

                                resolve(true)
                            })
                            .catch((error) => { 
                                this.$message.error('Ошибка удаления')
                                reject(error)
                            })
                    })
                }
            })
        },
        isAuthor(id) {
            return this.org?.director?.id === id
        },
        async getList() {
            try {
                this.loading = true

                let params = {
                    page: this.page,
                    page_size: this.pageSize,
                    page_name: this.page_name,
                    text: this.searchText
                }
                await this.getEmployeeList({ 
                    params: params, 
                    key: this.org.id,
                    isDepartment: this.isDepartment
                })
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        sizeSwicth(current, pageSize) {
            this.page = 1
            this.pageSize = Number(pageSize)
            this.getList()
        },
        changePage(page) {
            this.page = page
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>
.org_user_table{
    &::v-deep{
        .ant-table-thead{
            background: #ffffff;
        }
    }
}
.tag{
    // font-size: 8px;
    // padding: 0 5px;
    line-height: 17px;
}
.tag_custom_margin {
    margin-left: 0.125rem;
}


.user_card{
    padding: 12px;
    zoom: 1;
    color: #505050;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    background: #fff;
    border-radius: var(--borderRadius);
    border: 1px solid var(--border1);
    margin-bottom: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    &__row{
        display: flex;
        align-items: center;
        &:not(:last-child){
            margin-bottom: 5px;
        }
        &--label{
            margin-right: 5px;
            color: var(--gray);
        }
    }
}
</style>